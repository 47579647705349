import React from 'react'
import Logout from './Logout'

export default function Footer() {
  return (
    <>
    <div>
        Footer
    </div>
    <Logout/>
    </>
  )
}
